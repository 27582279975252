import { sum, truncate } from 'lodash'
import mixpanel from "mixpanel-browser";

mixpanel.init("55958fe31f7b2411a3a8dd45c5b6f4ab", {
	debug: false,
	track_pageview: true,
	persistence: "localStorage",
});

export function identify(user) {
	mixpanel.identify(user.id)
}

export function sendEvent(event, data) {
	mixpanel.track(event, data)
}

const copyToClipboard = str => {
	const el = document.createElement('textarea')
	el.value = str
	document.body.appendChild(el)
	el.select()
	document.execCommand('copy')
	document.body.removeChild(el)
}

const textToSpeech = (text) => {
	const utterance = new SpeechSynthesisUtterance(text)

	// TODO set voice based on current browser language
	// speechSynthesis.getVoices()

	return speechSynthesis.speak(utterance)
}

const enrichContent = (text) => {

	// make links clickable
	text = text.replace(/(http[s]?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>')

	return text
}

// truncate a string to a max length
// if length is longer than limit, add ellipsis symbol at the end
const truncateString = (string, length = 25) => truncate(string, { length, omission: '…' })

const average = arr => sum(arr) / arr.length

const numberToCurrency = (number, currency = 'usd', locale = 'en-US') => {
	return Number(number).toLocaleString(locale, {
		style: 'currency',
		currency,
	})
}

const privateRegistrants = [
	'Privacy service provided by Withheld for Privacy ehf',
]

const isPrivateRegistrant = registrant => {
	registrant = String(registrant).trim().toLowerCase()

	if (privateRegistrants.includes(registrant)) {
		return true
	}

	if (registrant.includes('proxy') || registrant.includes('private') || registrant.includes('privacy') || registrant.includes('protect') || registrant.includes('pending renewal') || registrant.includes('whoisguard') || registrant.includes('pending backorder') || registrant.includes('on behalf') || registrant.includes('reactivation period') || registrant.includes('anonymize') || registrant.includes('registrant')) {
		return true
	}

	return false
}

export {
	average,
	copyToClipboard,
	isPrivateRegistrant,
	textToSpeech,
	enrichContent,
	truncateString,
	numberToCurrency,
}
